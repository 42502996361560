import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import { useLanguages } from '../hooks/useLanguages'
import loadable from '@loadable/component'
import Helmet from "react-helmet"

const AireLanding = ({ location }) => {
  const [currLanguage] = useLanguages()
  const [TabComponent, setTabComponent] = useState()

  const aireFrameworkTabs = [
      ["Appreciation", "Incentivization", "Re-inforcement", "eMotional Connect"],
      [
        {
          tab: "Appreciation",
          desc: `Appreciation refers to an acknowledgment of employee contributions. The larger culture of recognition is determined by the extent of the employee population covered by the program and the frequency of formal recognition. Thus making Coverage and Frequency the key drivers of Appreciation.`,
          image:
            "/2024/06/AIRe-main-appreciation.webp",
          top: "28",
          tabColor: '#ff6d05'
        },
        {
          tab: "Incentivization",
          desc:
            "Incentivization is the practice of building incentives into an arrangement or system to motivate the workforce. The perceived value of the recognition element is relative and varies based on individual perception/preference. If employees are not clear about the objective of recognition it will not be able to drive impact effectively. Thus making Impetus & Clarity key drivers for Incentivization.",
          image:
            "/2024/06/AIRe-main-incentivization.webp",
          top: "28",
          tabColor: '#f02e4d'
        },
        {
          tab: "Re-inforcement",
          desc: `Reinforcement refers to positive communication based on the demonstration of key behaviors that the organization wants to promote. When a particular behavior is frequently recognized, employees understand what their employer expects from them. Thus, making Specificity & Alignment key drivers for Reinforcement, ensuring every recognition is meaningful and can be aligned with overall organizational goals.`,
          image:
            "/2024/11/aire-main-3.webp",
          top: "16",
          tabColor: '#00a68e'
        },
        {
          tab: "eMotional Connect",
          desc:
            `AIR<sup>e</sup> puts special emphasis on the emotions involved in the act of recognition. The impact of recognition comes from how it is given and the emotional value behind it. Thereby making eMotional Connect as the driving force behind recognition, with Personalization & Delivery as its key drivers.`,
          image:
            "/2024/06/AIRe-main-emotionalconnect.webp",
          top: "20",
          tabColor: '#000000'
        },
      ],
      {
        color: "bg-white",
        bgImage: "feature-BG-Metrics.webp",
      },
    ]

const fuelRnROfferingCards = [
    {
       image: '2024/06/Aire-Assessment.webp',
       cardName : 'R&R Self-Assessment',
       cardDesc: `Optimize your R&R strategy, answer a few questions, and get a data-driven scorecard of your R&R program.`,
       exploreLink: 'https://www.vantagecircle.com/tools/aire-score-assessment/'
    },
    {
        image: '2024/06/Aire-Consultant.webp',
        cardName : 'R&R Advisory',
        cardDesc: `Expert audit & consultation from design to implementation. Leverage our experience to create a personalized program that drives effective employee engagement.`,
        exploreLink: 'https://www.vantagecircle.com/services/aire-consultation/'
     },
     {
        image: '2024/06/Aire-Masterclas.webp',
        cardName : 'R&R Certification Courses',
        cardDesc: `Level up your R&R skills with AIR<sup>e</sup> training. Build impactful programs, master best practices, and unlock the power of recognition, drive engagement, and productivity.`,
        exploreLink: 'https://hracademy.vantagecircle.com/course/HR-ESSENTIALS-FROM-RECOGNITION-TO-RESULTS-72632'
     },
     {
        image: '2024/06/Aire-Reports.webp',
        cardName : 'Research Reports',
        cardDesc: `Vantage Circle's R&R reports offer global insights and industry trends, with geo-specific breakdowns (UAE, India, UK, USA). Benchmark your strategy and tailor programs for diverse workforces with these data-driven reports.`,
        exploreLink: 'https://www.vantagecircle.com/recognition-and-rewards-reports/'
     },
]

    const checkWidth = () => {
        if(window !== 'undefined'){
            if(window.innerWidth >= 1024){             
                const Tab= loadable(() => import('../components/Tab'))
                setTabComponent(<Tab tabData={aireFrameworkTabs} />)
            }
            else{
                const MobileTab= loadable(() => import('../components/MobileTab'))
                setTabComponent(<MobileTab tabData={aireFrameworkTabs}/>)
            }
        }
    }

    useEffect(() => {
        checkWidth()
    }, [])
    
    
  return (
    <Layout location={location} pageLanguage={currLanguage}>
        <Helmet>
        <title>
        The Behavioral science-powered AIRᵉ Framework for Employee Recognition
        </title>

        <meta
          name="description"
          content="Vantage Circle's decade of experience and learnings led us to build the AIRe Structure, a new framework and strategy for employee recognition."
        />
        <meta
          property="twitter:title"
          content="The Behavioral science-powered AIRᵉ Framework for Employee Recognition"
        />
        <meta name="robots" content="index" />

        <meta
          property="twitter:description"
          content="Vantage Circle's decade of experience and learnings led us to build the AIRe Structure, a new framework and strategy for employee recognition."
        />

        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content="https://www.vantagecircle.com/products/rewards-recognition/"
        />

        <meta property="og:site_name" content="Vantage Circle" />
        <meta
          property="og:title"
          content="The Behavioral science-powered AIRᵉ Framework for Employee Recognition"
        />

        <meta
          property="og:description"
          content="Vantage Circle's decade of experience and learnings led us to build the AIRe Structure, a new framework and strategy for employee recognition."
        />
        <link
          rel="canonical"
          href="https://www.vantagecircle.com/products/rewards-recognition/"
        ></link>
      </Helmet>
       <section id='watchVideoClass' className="w-11/12 2xl:max-w-7xl mx-auto rounded-xl flex place-content-center relative bg-purple-80 pt-9 xl:py-0 mb-5 overflow-hidden z-0 px-2 lg:px-0">
            <div className="w-full xl:flex justify-center items-start z-10 lg:px-10 relative">
                <div className="w-full md:w-11/12 xl:w-7/12 mx-auto flex flex-col justify-center lg:items-start items-center xl:mr-3 px-3 xl:pt-12 xl:pb-20">
                        <div className="mb-2 flex justify-center xl:justify-start items-center w-full">
                            <picture>
                                <source className='z-10 relative' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Homepage-AIRe.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="110" height="90" />
                                <img className='z-10 relative' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2023/05/Homepage-AIRe.webp" alt="Vantage Perks" width="110" height="90" />
                            </picture>
                        </div>
                        <h1 className="text-indigo-100 lg:pr-3 xl:w-full homepage-section-heading text-center xl:text-left relative pt-3 xl:pt-0">Behavioral science-powered framework for employee recognition</h1>
                        <p className="my-4 xl:my-8 text-indigo-100 text-center xl:text-left w-full text-black" style= {{ lineHeight: '2rem' }}>Our proprietary framework to ensure effective design and execution of your employee Recognition & Rewards programs.</p>
                        <div className="flex flex-col xl:flex-row justify-center xl:justify-start items-center w-full mx-auto xl:mx-0 md:w-1/2 lg:w-full xl:w-11/12 2xl:w-full cursor-pointer mb-8 mt-5 z-10">
                            <a className="vc-new-orange-btn-rounded-full mx-auto lg:mx-0 text-white lato rounded-full mb-3 lg:mb-2 xl:mb-0 xl:mr-2" style={{ padding: '0.8rem 1rem' }} href="https://www.vantagecircle.com/tools/aire-score-assessment/">Access your R&R Program</a>
                            <a className="vc-new-orange-border-btn-rounded-full text-newOrange lato rounded-full lg:mt-0 xl:ml-2" href='https://www.vantagecircle.com/services/aire-consultation/' style={{ padding: '0.8rem 1rem' }}>Request R&R Consultation</a>
                        </div>      
                </div>
                {/* <div className="lg:w-5/12 md:h-96 xl:h-80"></div> */}
                <div className='xl:w-5/12 relative xl:h-full grid xl:flex items-end'>
                    <div className='grid mx-auto xl:mx-0 w-5/6 grid-cols-4 xl:flex'>
                        <picture>
                            <source className='z-10 relative' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/AIRE-Main-ico4.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="100" />
                            <img title='R&R Course' className='mx-auto mt-2 z-10 xl:absolute top-1/2 -left-6 transform transition duration-300 scale-125 xl:scale-105 hover:scale-125 xl:-translate-y-5 2xl:-translate-y-3' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/06/AIRE-Main-ico4.webp" alt="Vantage Perks" width="100" />
                        </picture>
                        <picture>
                            <source className='z-10 relative' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/AIRE-Main-ico3.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="100" />
                            <img title='Assesssment' className='mx-auto mt-2 z-10 xl:absolute top-1/3 left-4 transform transition duration-300 scale-125 xl:scale-105 hover:scale-125 xl:-translate-y-6 2xl:-translate-y-4' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/06/AIRE-Main-ico3.webp" alt="Vantage Perks" width="100" />
                        </picture>
                        <picture>
                            <source className='z-10 relative' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/AIRE-Main-ico2.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="100" />
                            <img title='Consultation' className='mx-auto mt-2 z-10 xl:absolute top-1/4 left-28 transform transition duration-300 scale-125 xl:scale-105 hover:scale-125 xl:-translate-y-11 2xl:-translate-y-7' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/06/AIRE-Main-ico2.webp" alt="Vantage Perks" width="100" />
                        </picture>
                        <picture>
                            <source className='z-10 relative' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/AIRE-Main-ico1.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="100" />
                            <img title='Reports' className='mx-auto mt-2 z-10 xl:absolute top-1/4 left-1/2 transform transition duration-300 scale-125 xl:scale-105 hover:scale-125 xl:-translate-y-12 2xl:-translate-y-7 xl:-translate-x-6 2xl:-translate-x-7' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/06/AIRE-Main-ico1.webp" alt="Vantage Perks" width="100" />
                            {/* <p className='p-2 tooltipText absolute top-5'>Reports</p> */}
                        </picture>
                    </div>
                    {/* <div className="xl:absolute xl:scale-125 xl:transform lg:bottom-0 xl:-bottom-12 xl:-bottom-24 xl:-translate-y-6 2xl:-translate-y-2 xl:right-5 flex justify-center items-end xl:items-center lg:h-full xl:mt-0 lg:ml-3">
                        <picture>
                            <source className='z-10 relative object-cover' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/AIRe-Main-hero.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="500" />
                            <img className='z-10 relative object-cover' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/06/AIRe-Main-hero.png" alt="Vantage Perks" width="500" height="572" />
                        </picture>
                    </div> */}
                    <div className="xl:absolute xl:scale-125 xl:transform lg:bottom-0 xl:-translate-y-10 2xl:-translate-y-10 xl:right-5 flex justify-center items-end mt-4 xl:mt-0 lg:ml-3">
                        <picture>
                            <source className='z-10 relative object-cover' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/AIRe-Main-hero.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="500" />
                            <img className='z-10 relative object-cover' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/06/AIRe-Main-hero.png" alt="Vantage Perks" width="500" height="572" />
                        </picture>
                    </div>
                </div>  
            </div>   
        </section>  

        <section className='py-10 xl:py-16 w-full'>
            <div className='px-5 max-w-6xl mx-auto'>
                <h2 className='homepage-section-heading text-center'>Shaping recognition programs worldwide</h2>
                <div className='grid grid-cols-2 xl:grid-cols-4 gap-5 place-content-center mt-10'>
                    <div className='grid justify-center items-center'>
                        <h2 className='homepage-section-heading text-center xl:text-left'>10<span className='text-newOrange'>+</span></h2>
                        <p className='text-center xl:text-left'>Reports</p>
                    </div>
                    <div className='grid justify-center items-center'>
                        <h2 className='homepage-section-heading text-center xl:text-left'>2500<span className='text-newOrange'>+</span></h2>
                        <p className='text-center xl:text-left'>HR Responses</p>
                    </div>
                    <div className='grid justify-center items-center'>
                        <h2 className='homepage-section-heading text-center xl:text-left'>7<span className='text-newOrange'>+</span></h2>
                        <p className='text-center xl:text-left'>Recognition Reports</p>
                    </div>
                    <div className='grid justify-center items-center'>
                        <h2 className='homepage-section-heading text-center xl:text-left'>2000<span className='text-newOrange'>+</span></h2>
                        <p className='text-center xl:text-left'>Companies</p>
                    </div>
                </div>
            </div>
        </section>

        <section className='py-10 xl:py-16 px-5 w-full bg-purple-100'>
            <h2 className='mx-auto text-center homepage-section-heading'>Understanding the AIR<sup>e</sup> Framework</h2>
            <p className='text-center mx-auto py-5 max-w-5xl text-gray-500'>
            Drawing on a decade of HR expertise, Vantage Circle's proprietary AIR<sup>e</sup> framework transforms Recognition & Reward (R&R) programs. 
            It is a framework and strategy to design, implement, and execute an authentic and impactful R&R program for employees with 
            Appreciation (A), Incentivization (I), and Reinforcement (R) 
            as the key themes of recognition and Emotional Connect (e) as the driving force.
            </p>
           {
           }
            {TabComponent}

            <div className='max-w-7xl mx-auto lg:pt-9 flex justify-center lg:justify-start'>
                <a href="https://www.vantagecircle.com/hr-academy/whitepapers/aire-framework/" className='text-center lg:text-left text-orange underline'>Download the AIR<sup>e</sup> Whitepaper</a>
            </div>
        </section>

        <section className='py-10 xl:py-16 flex place-content-center px-5'>
            <div className='max-w-7xl mx-auto'>
                <h2 className='homepage-section-heading text-center'>
                    Fuel your R&R success with our AIR<sup>e</sup> offerings
                </h2>
                <div className='lg:grid grid-cols-2 gap-6 xl:gap-16 place-content-center lg:mt-10'>
                    {
                        fuelRnROfferingCards.map(card => {
                            return(
                                <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-xl overflow-hidden mx-auto my-10 lg:my-0">
                                    <div className="md:w-4/12 lg:w-5/12 xl:w-4/12 p-5">
                                        <picture>
                                            <source className='w-full h-auto md:h-full rounded-lg' srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_316/q_auto/gatsbycms/uploads/${card.image}`} media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="316" />
                                            <img className='w-full h-auto md:h-full rounded-lg' src={`https://res.cloudinary.com/vantagecircle/image/upload/w_316/q_auto/gatsbycms/uploads/${card.image}`} alt="Vantage Perks" width="316" />
                                        </picture>
                                    </div>
                                    <div className="md:w-8/12 lg:w-7/12 xl:w-8/12 p-5 lg:pl-0 xl:pl-5 flex flex-col justify-center">
                                        <h2 className="text-xl mb-2">{card.cardName}</h2>
                                        <p className="mb-4 text-base text-gray-500" dangerouslySetInnerHTML={{ __html: card.cardDesc }} />
                                        <a href={card.exploreLink} className="text-orange">Explore <span className=' duration-300 transform translate-0 transition hover:translate-x-5'>&gt;</span></a>
                                    </div>
                                </div>
                            )
                        })
                    }  
                </div>
            </div>
        </section>

        <section className='pb-10 lg:py-10'>
            <div className='max-w-7xl mx-auto bg-indigo-100 py-10 xl:py-16 2xl:rounded-xl px-5'>
                <h2 className='homepage-section-heading text-center text-purple-100'>
                    Center of Excellence:
                </h2>
                <h2 className='homepage-section-heading text-center text-purple-100'>
                    Leading the way in employee engagement
                </h2>
                <p className='text-center text-purple-100 mt-8 max-w-5xl mx-auto'>
                 Center of Excellence (CoE) - People and Culture is the research and development wing of Vantage Circle,
                 working tirelessly to provide the employees, 
                 HR practitioners, top management, and other stakeholders in the community 
                 with best practices around the employee engagement space.
                </p>
                <div className='md:grid md:grid-cols-2 xl:grid-cols-3 gap-3 place-content-center mx-auto max-w-6xl mt-10 xl:mt-16'>
                    <div className='rounded-xl p-5 grid place-content-center my-4 md:my-0 h-full' style={{ backgroundColor: '#6a6aaf'}}>
                        <h2 className='homepage-div-small-heading text-purple-100'>Goal</h2>
                        <p className='text-purple-100 pt-3 text-base'>To eliminate inefficiency and help move the organization to the next level of the employee engagement ladder.</p>
                    </div>
                    <picture>
                        <source className='w-full h-auto md:h-full rounded-xl' srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2024/06/AireCOE-1.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="316" />
                        <img className='w-full h-auto md:h-full rounded-xl' src="https://res.cloudinary.com/vantagecircle/image/upload/w_316/q_auto/gatsbycms/uploads/2024/06/AireCOE-1.webp" alt="Vantage Perks" width="316" />
                    </picture>
                    <div className='rounded-xl p-5 grid place-content-center my-4 md:my-0 h-full' style={{ backgroundColor: '#6a6aaf'}}>
                        <h2 className='homepage-div-small-heading text-purple-100'>Result</h2>
                        <p className='text-purple-100 pt-3 text-base'>A systematic approach and knowledge to implement HR initiatives that can facilitate the best employee experience.</p>
                    </div>
                    <picture>
                        <source className='w-full h-auto md:h-full rounded-xl' srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2024/06/AIReCOE-2.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="316" />
                        <img className='w-full h-auto md:h-full rounded-xl' src="https://res.cloudinary.com/vantagecircle/image/upload/w_316/q_auto/gatsbycms/uploads/2024/06/AIReCOE-2.webp" alt="Vantage Perks" width="316" />
                    </picture>
                    <div className='rounded-xl p-5 grid place-content-center my-4 md:my-0 h-full' style={{ backgroundColor: '#6a6aaf'}}>
                        <h2 className='homepage-div-small-heading text-purple-100'>Objective</h2>
                        <p className='text-purple-100 pt-3 text-base'>Build HR expertise through global collaboration on reward and recognition with stakeholders and experts.</p>
                    </div>
                    <picture>
                        <source className='w-full h-auto md:h-full rounded-xl' srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2024/06/AIReCOE-3.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="316" />
                        <img className='w-full h-auto md:h-full rounded-xl' src="https://res.cloudinary.com/vantagecircle/image/upload/w_316/q_auto/gatsbycms/uploads/2024/06/AIReCOE-3.webp" alt="Vantage Perks" width="316" />
                    </picture>
                </div>
            </div>
        </section>

        <section className='py-10 xl:pt-16 flex place-content-center px-5'>
            <div className='max-w-7xl mx-auto w-11/12'>
                <h2 className='homepage-section-heading text-center'>Explore our blogs</h2>
                <p className='text-center mt-5 mb-10 max-w-4xl mx-auto'>Explore the latest insights on recognition, engagement strategies, AI advancements, and behavioral science in our curated blog section. Stay informed and inspired with articles crafted to enrich your understanding and approach to these vital subjects.</p>
                <div className='md:grid md:grid-cols-2 xl:grid-cols-3 gap-6 mt-4 xl:mt-0'>
                    <div className='p-3 border rounded-xl border-gray-light my-5 lg:my-0 relative'>
                        <picture>
                            <source className='w-full rounded-xl' srcset="https://blogimage.vantagecircle.com/vcblogimage/en/2024/08/VC_Featured-Image-Light-1.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="316" />
                            <img className='w-full rounded-xl' src="https://blogimage.vantagecircle.com/vcblogimage/en/2024/08/VC_Featured-Image-Light-1.png" alt="Vantage Perks" width="316" />
                        </picture>
                        <p className='pt-5 px-3 truncate text-line-clamp-3 homepage-mini-heading text-gray-500'>
                        Beyond the Trophy: A Gen Z Perspective on R&R
                        </p>
                        <a href='https://www.vantagecircle.com/en/blog/gen-z-perspective-on-r-r/' className='text-orange py-5 px-3'>Read now</a>
                    </div>
                    <div className='p-3 border rounded-xl border-gray-light my-5 lg:my-0 relative'>
                        <picture>
                            <source className='w-full rounded-xl' srcset="https://blogimage.vantagecircle.com/vcblogimage/en/2024/08/VC_Featured-Image-Light.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="316" />
                            <img className='w-full rounded-xl' src="https://blogimage.vantagecircle.com/vcblogimage/en/2024/08/VC_Featured-Image-Light.png" alt="Vantage Perks" width="316" />
                        </picture>
                        <p className='pt-5 px-3 truncate text-line-clamp-3 homepage-mini-heading text-gray-500'>
                        Understanding Service Awards and Taxation Benefits in the US and Canada
                        </p>
                        <a href='https://www.vantagecircle.com/en/blog/service-awards-and-tax-benefits/' className='text-orange py-5 px-3'>Read now</a>
                    </div>
                    <div className='p-3 border rounded-xl border-gray-light my-5 lg:my-0 relative'>
                        <picture>
                            <source className='w-full rounded-xl' srcset="https://blogimage.vantagecircle.com/vcblogimage/en/2024/07/VC_Featured-Image-Light-4.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="316" />
                            <img className='w-full rounded-xl' src="https://blogimage.vantagecircle.com/vcblogimage/en/2024/07/VC_Featured-Image-Light-4.png" alt="Vantage Perks" width="316" />
                        </picture>
                        <p className='pt-5 px-3 truncate text-line-clamp-3 homepage-mini-heading text-gray-500'>
                        Sustainable Leadership: Incorporating Recognition into Long-term Business Strategies
                        </p>
                        <a href='https://www.vantagecircle.com/en/blog/sustainable-leadership-and-recognition/' className='text-orange py-5 px-3'>Read now</a>
                    </div>
                </div>
            </div>
        </section>

        {/* <section className='py-10 xl:py-16 flex place-content-center px-5'>
            <div className='max-w-7xl mx-auto bg-indigo-100 rounded-xl lg:flex'>
                <div className='w-full lg:w-7/12 p-10'>
                    <h2 className='homepage-div-heading text-purple-100'>
                        Take AIR<sup>e</sup> Assessment today
                    </h2>
                    <p className='mb-5 mt-2 text-purple-100'>and receive a data-backed quality score for your RnR program.</p>
                    <a href='https://www.vantagecircle.com/tools/aire-score-assessment/' className='vc-new-white-border-btn-rounded-full mt-2'>Assess your program</a>
                </div>
                <div className='w-full lg:flex items-end justify-center lg:w-4/12 pt-8'>
                    <picture>
                        <source className='w-full rounded-xl' srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2024/06/AIRe-Assesment-banner.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="316" />
                        <img className='w-full rounded-xl' src="https://res.cloudinary.com/vantagecircle/image/upload/w_316/q_auto/gatsbycms/uploads/2024/06/AIRe-Assesment-banner.webp" alt="Vantage Perks" width="316" />
                    </picture>
                </div>
            </div>
        </section> */}

        <section className='pb-10 md:py-10 w-full px-5 lg:px-0'>
          <div className='w-full lg:w-11/12 lg:max-w-7xl mx-auto bg-indigo-100 lg:flex justify-around rounded-xl p-7 pt-10 pb-0 lg:px-5 xl:p-0'>
              <div className='my-auto grid justify-center lg:justify-start'>
                  <h2 className='homepage-div-heading text-purple-100 text-center lg:text-left'>Take AIR<sup>e</sup> Assessment today</h2>
                  <p className='text-purple-100 pt-3 pb-5 text-center lg:text-left'>And receive a data-backed quality score for your R&R program.</p>
                  <a href='https://www.vantagecircle.com/tools/aire-score-assessment/' className='text-center rounded-full p-4 text-purple-100 border border-purple-100 lg:w-1/2'>
                    Assess my program <span className='text-lightgreen text-sm font-bold'> &nbsp; &nbsp;FREE!</span>
                  </a>
              </div>

              <div className='flex items-end justify-center pt-10 lg:pt-3'>
                  <picture>
                      <source className='z-10 relative' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/AIRe-Assesment-banner.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="450" />
                      <img className='mx-auto mt-2 z-10' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/06/AIRe-Assesment-banner.webp" alt="Vantage Perks" width="300" />
                  </picture>
              </div>
          </div>
        </section>
    </Layout>
  )
} 

export default AireLanding